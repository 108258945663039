body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.sideBar-button {
  padding-top: 6px !important;
  padding-right: 8px !important;
  padding-bottom: 6px !important;
}

/* Banner */

#banner {
  padding: 12em 0 10em 0;
  background-image: url("https://stmintstudiouat.z1.web.core.windows.net/assets/banner.jpg");
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  background-repeat: no-repeat;
  text-align: center;
  border-top: 0.5em solid #5385c1;
}

#banner h1 {
  font-size: 3.5em;
  font-weight: 100;
  color: #fff;
  line-height: 1em;
  margin: 0 0 0.5em 0;
  padding: 0;
}

#banner p {
  font-size: 1em;
  color: rgba(255, 255, 255, 0.55);
  margin-bottom: 1.75em;
}

#newImage {
	text-align: center;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #banner {
    background-attachment: scroll;
  }
}

@media screen and (max-width: 1280px) {
  #banner {
    padding: 10em 2em 8em 2em;
  }
}

@media screen and (max-width: 980px) {
  #banner {
    background-attachment: scroll;
  }
}

@media screen and (max-width: 736px) {
  #banner {
    padding: 8em 1.5em 6em 1.5em;
  }

  #banner h1 {
    font-size: 2.5em;
  }

  #banner p {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 480px) {
  #banner {
    padding: 6em 1.5em 4em 1.5em;
  }
}

/* roboto-300 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("https://stmintstudiouat.z1.web.core.windows.net/assets/robotofont/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("https://stmintstudiouat.z1.web.core.windows.net/assets/robotofont/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("https://stmintstudiouat.z1.web.core.windows.net/assets/robotofont/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("https://stmintstudiouat.z1.web.core.windows.net/assets/robotofont/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("https://stmintstudiouat.z1.web.core.windows.net/assets/robotofont/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("https://stmintstudiouat.z1.web.core.windows.net/assets/robotofont/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("https://stmintstudiouat.z1.web.core.windows.net/assets/robotofont/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("https://stmintstudiouat.z1.web.core.windows.net/assets/robotofont/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"),
    url("https://stmintstudiouat.z1.web.core.windows.net/assets/robotofont/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("https://stmintstudiouat.z1.web.core.windows.net/assets/robotofont/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.custom-chip-style:focus-within {
  border: 2px solid #e31c3d;
}

.ag-theme-balham .ag-header-cell-label {
  color: #6b6c6c !important;
}

.ag-theme-balham .ag-keyboard-focus .ag-header-cell:focus:after {
  border-color: #101d3a !important;
}

.ag-theme-balham .ag-status-name-value {
  color: #6b6c6c !important;
}

.ag-theme-balham .ag-keyboard-focus .ag-column-drop-cell:focus::after {
  border: 2px solid #4951B0 !important;
}

.he-copilot {
  height: 100%;
}

.react-copilot-chat {
  height: 100%;
}

iframe {
  display: none;
}